var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", scrollable: "", width: "800" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-gateway" }),
              _vm._v(_vm._s(_vm.$t("Add Gateway")) + " ")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c("p", { staticClass: "label-txt" }, [
                _vm._v(_vm._s(_vm.$t("Register Type")))
              ]),
              _c(
                "v-radio-group",
                {
                  staticClass: "form-radio-group",
                  attrs: { row: "", dense: "", "hide-details": "" },
                  model: {
                    value: _vm.radioValue,
                    callback: function($$v) {
                      _vm.radioValue = $$v
                    },
                    expression: "radioValue"
                  }
                },
                [
                  _c("v-radio", {
                    staticClass: "form-radio flat",
                    attrs: {
                      "hide-details": "",
                      label: _vm.$t("Scan"),
                      value: "Scan"
                    }
                  }),
                  _c("v-radio", {
                    staticClass: "form-radio flat",
                    attrs: {
                      "hide-details": "",
                      label: _vm.$t("Direct"),
                      value: "Direct"
                    }
                  })
                ],
                1
              ),
              _vm.radioValue === _vm.registerType.scan
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "searchArea flexable" },
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              rules: [_vm.rules.ip],
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Start") + " *",
                              placeholder: _vm.$t("Input the start IP"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.scanData.startIp,
                              callback: function($$v) {
                                _vm.$set(_vm.scanData, "startIp", $$v)
                              },
                              expression: "scanData.startIp"
                            }
                          }),
                          _c("v-text-field", {
                            staticClass: "form-input ml-1",
                            attrs: {
                              rules: [_vm.rules.ip],
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("End") + "*",
                              placeholder: _vm.$t("Input the last IP"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.scanData.endIp,
                              callback: function($$v) {
                                _vm.$set(_vm.scanData, "endIp", $$v)
                              },
                              expression: "scanData.endIp"
                            }
                          }),
                          _c("v-select", {
                            staticClass: "form-select ml-1",
                            staticStyle: { width: "110px" },
                            attrs: {
                              items: _vm.scanOptions.uriSchemes,
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Protocol Type"),
                              placeholder: _vm.$t("Select the protocol type")
                            },
                            on: {
                              change: function($event) {
                                return _vm.setPortByProtocol(_vm.scanData)
                              }
                            },
                            model: {
                              value: _vm.scanData.uriScheme,
                              callback: function($$v) {
                                _vm.$set(_vm.scanData, "uriScheme", $$v)
                              },
                              expression: "scanData.uriScheme"
                            }
                          }),
                          _c("v-text-field", {
                            staticClass: "form-select ml-1",
                            staticStyle: { width: "110px" },
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Port"),
                              placeholder: _vm.$t("Select the port"),
                              type: "number"
                            },
                            model: {
                              value: _vm.scanData.port,
                              callback: function($$v) {
                                _vm.$set(_vm.scanData, "port", $$v)
                              },
                              expression: "scanData.port"
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn type-search ml-1",
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.scanGateways()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Scan")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-data-table",
                        {
                          staticClass: "tbl-type01 flat-type02 mt-5",
                          attrs: {
                            headers: _vm.scanHeaders,
                            items: _vm.scannedGateways,
                            page: _vm.page,
                            "hide-default-footer": true,
                            "disable-pagination": "",
                            "item-key": "ip",
                            "show-select": "",
                            "fixed-header": "",
                            height: "275"
                          },
                          on: {
                            "update:page": function($event) {
                              _vm.page = $event
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item.gatewayName",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "form-rewrite" },
                                      [
                                        _c("v-text-field", {
                                          key: item.gatewayNameDisabled,
                                          ref: item.gateway,
                                          staticClass: "form-input underline",
                                          attrs: {
                                            value: item.gatewayName,
                                            rules: [_vm.rules.gatewayName],
                                            solo: item.gatewayNameDisabled,
                                            readonly: item.gatewayNameDisabled,
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.handlegatewayNameChange(
                                                $event,
                                                item
                                              )
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append",
                                                fn: function() {
                                                  return [
                                                    item.gatewayNameDisabled
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              "x-small": ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.editgatewayName(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-pencil"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              "x-small": "",
                                                              hidden: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.savegatewayName(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t("OK")
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.siteCode",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "form-rewrite" },
                                      [
                                        _c("v-text-field", {
                                          key: item.siteCodeDisabled,
                                          ref: item.gateway,
                                          staticClass: "form-input underline",
                                          attrs: {
                                            value: item.siteCode,
                                            rules: [_vm.rules.siteCode],
                                            solo: item.siteCodeDisabled,
                                            readonly: item.siteCodeDisabled,
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.handleSiteCodeChange(
                                                $event,
                                                item
                                              )
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append",
                                                fn: function() {
                                                  return [
                                                    item.siteCodeDisabled
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              "x-small": ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.editSiteCode(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-pencil"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              "x-small": "",
                                                              hidden: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.saveSiteCode(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t("OK")
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.scanSelected,
                            callback: function($$v) {
                              _vm.scanSelected = $$v
                            },
                            expression: "scanSelected"
                          }
                        },
                        [
                          _c("template", { slot: "no-data" }, [
                            _c("p", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("No data available")) + " "
                              )
                            ])
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "searchArea flexable" },
                        [
                          _c("v-select", {
                            staticClass: "form-select selected",
                            attrs: {
                              items: _vm.directOptions.codes,
                              "item-text": "text",
                              "item-value": "abbr",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Code") + " *",
                              placeholder: "" + _vm.$t("Choose the code")
                            },
                            on: { change: _vm.handleCodeChange },
                            model: {
                              value: _vm.directData.code,
                              callback: function($$v) {
                                _vm.$set(_vm.directData, "code", $$v)
                              },
                              expression: "directData.code"
                            }
                          }),
                          _c("v-text-field", {
                            ref: "directAddress",
                            staticClass: "form-input ml-1",
                            attrs: {
                              rules: _vm.getDirectRuleByCode(),
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              placeholder: "" + _vm.$t("Input the Address"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.directData.gateway,
                              callback: function($$v) {
                                _vm.$set(_vm.directData, "gateway", $$v)
                              },
                              expression: "directData.gateway"
                            }
                          }),
                          _c("v-select", {
                            staticClass: "form-select ml-1",
                            staticStyle: { width: "110px" },
                            attrs: {
                              items: _vm.directOptions.uriSchemes,
                              disabled: _vm.macSelected,
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "" + _vm.$t("Protocol Type"),
                              placeholder:
                                "" + _vm.$t("Select the protocol type")
                            },
                            on: {
                              change: function($event) {
                                return _vm.setPortByProtocol(_vm.directData)
                              }
                            },
                            model: {
                              value: _vm.directData.uriScheme,
                              callback: function($$v) {
                                _vm.$set(_vm.directData, "uriScheme", $$v)
                              },
                              expression: "directData.uriScheme"
                            }
                          }),
                          _c("v-text-field", {
                            staticClass: "form-select ml-1",
                            staticStyle: { width: "110px" },
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "" + _vm.$t("Port"),
                              placeholder: "" + _vm.$t("Select the port"),
                              type: "number"
                            },
                            model: {
                              value: _vm.directData.port,
                              callback: function($$v) {
                                _vm.$set(_vm.directData, "port", $$v)
                              },
                              expression: "directData.port"
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn type-search ml-1",
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.addDirectGateway()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Add")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-data-table",
                        {
                          staticClass: "tbl-type01 flat-type02 mt-5",
                          attrs: {
                            headers: _vm.directHeaders,
                            items: _vm.directGateways,
                            "item-key": "gateway",
                            "show-select": "",
                            "hide-default-footer": true,
                            "fixed-header": "",
                            height: "275"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item.gatewayName",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "form-rewrite" },
                                      [
                                        _c("v-text-field", {
                                          key: item.gatewayNameDisabled,
                                          ref: item.gateway,
                                          staticClass: "form-input underline",
                                          attrs: {
                                            value: item.gatewayName,
                                            rules: [_vm.rules.gatewayName],
                                            solo: item.gatewayNameDisabled,
                                            readonly: item.gatewayNameDisabled,
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.handlegatewayNameChange(
                                                $event,
                                                item
                                              )
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append",
                                                fn: function() {
                                                  return [
                                                    item.gatewayNameDisabled
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              "x-small": ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.editgatewayName(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-pencil"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              "x-small": "",
                                                              hidden: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.savegatewayName(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t("OK")
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.siteCode",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "form-rewrite" },
                                      [
                                        _c("v-text-field", {
                                          key: item.siteCodeDisabled,
                                          ref: item.gateway,
                                          staticClass: "form-input underline",
                                          attrs: {
                                            value: item.siteCode,
                                            rules: [_vm.rules.siteCode],
                                            solo: item.siteCodeDisabled,
                                            readonly: item.siteCodeDisabled,
                                            dense: "",
                                            "hide-details": ""
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.handleSiteCodeChange(
                                                $event,
                                                item
                                              )
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append",
                                                fn: function() {
                                                  return [
                                                    item.siteCodeDisabled
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              "x-small": ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.editSiteCode(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-pencil"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              "x-small": "",
                                                              hidden: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.saveSiteCode(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t("OK")
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.del",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "", icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeDirectGateway(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/ico-delete2.png"),
                                            alt: ""
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.directSelected,
                            callback: function($$v) {
                              _vm.directSelected = $$v
                            },
                            expression: "directSelected"
                          }
                        },
                        [
                          _c("template", { slot: "no-data" }, [
                            _c("p", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("No data available")) + " "
                              )
                            ])
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _vm.radioValue === _vm.registerType.scan
            ? _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: {
                        text: "",
                        icon: "",
                        disabled: _vm.scanbuttonevent
                      },
                      on: { click: _vm.registerScannedGateways }
                    },
                    [_vm._v(_vm._s(_vm.$t("Register")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.cancelAddGateway }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            : _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "", disabled: _vm.buttonevent },
                      on: {
                        click: function($event) {
                          return _vm.registerDirectGateways()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Register")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancelAddGateway()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }