<template>
  <div>
    <v-dialog v-model="show" width="700" persistent scrollable>
      <v-card class="popup add_store_popup">
        <v-card-title>
          <h3 class="page-title-bar">
            <i class="ico ico-gateway"></i>{{ $t("Gateway Detail") }}
          </h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="gateway['macAddress']"
                outlined
                dense
                hide-details
                :label="`${$t('MAC Address')} *`"
                :placeholder="`${$t('Input the MAC Address')}`"
                class="form-input"
                disabled
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="gateway['name']"
                outlined
                dense
                ref="name"
                hide-details
                :label="`${$t('Name')}`"
                :placeholder="`${$t('Input the name')}`"
                class="form-input"
                :rules="[rules.gatewayName]"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
            <!-- start : 추가 -->
            <v-col>
              <v-text-field
                v-model="gateway.siteCode"
                ref="siteCode"
                :rules="[rules.siteCode]"
                outlined
                dense
                hide-details
                :label="$t('Sitecode')"
                :placeholder="$t('Input the Sitecode')"
                class="form-input"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
            <!-- end : 추가 -->
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="gateway['dataChennel']"
                outlined
                dense
                hide-details
                :label="`${$t('Data CH')}`"
                :placeholder="$t('Input the Data CH')"
                class="form-input"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                dense
                hide-details
                :label="`${$t('Wakeup CH')}`"
                :placeholder="$t('Input the Wakeup CH')"
                v-model="gateway['wakeupChennel']"
                class="form-input"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                dense
                hide-details
                :label="`${$t('Beacon CH ')}**`"
                :placeholder="$t('Input the Beacon CH')"
                v-model="gateway['beaconChennel']"
                class="form-input"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                hide-details
                :label="$t('Machine ID')"
                :placeholder="$t('Input the Int Machine ID')"
                v-model="gateway['machId']"
                class="form-input"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                dense
                hide-details
                :label="`${$t('Int Machine ID')} **`"
                :placeholder="`${$t('Input the Int Machine ID')}`"
                v-model="gateway['integMachId']"
                class="form-input"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                dense
                hide-details
                :label="`${$t('Refresh Start')}`"
                :placeholder="`${$t('Input the Refresh Start')}`"
                v-model="gateway['refreshStart']"
                class="form-input"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                outlined
                dense
                hide-details
                :label="`${$t('Number Display')}`"
                :placeholder="`${$t('Choose the Number Display')}`"
                :items="['Yes', 'No']"
                class="form-select"
                v-model="gateway['displayNoEnable']"
                clearable
                @keydown="clearTabindex"
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                dense
                hide-details
                :label="`${$t('Number')}`"
                :placeholder="`${$t('Input the Number')}`"
                v-model="gateway['displayNo']"
                class="form-input"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
            <v-col></v-col>
          </v-row>
          <p class="label-txt mt-5">
            <span><strong>*</strong> {{ $t("Mandatory") }}</span>
          </p>
          <p class="label-txt mt-1">
            <span>
              <strong>**</strong>
              {{ $t("Gateways will be rebooted by changing the config") }}
            </span>
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="updateSingleGateway" class="btn">{{
            $t("Save")
          }}</v-btn>
          <v-btn text icon @click="show = false" class="btn">{{
            $t("Cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import EventBus from '@/plugins/eventBus.js'
import commons from '@/plugins/commons'

const requests = {
  updateGateway: {
    method: 'put',
    url: '/api/common/gateway'
  }
}

export default {
  props: {
    value: Boolean,
    gateway: Object
  },
  data () {
    return {
      dialog: false,
      message: '',
      rules: {
        siteCode: value => /^[A-F0-9]{4}$/g.test(value),
        gatewayName: value => /^[a-zA-Z0-9_.]*$/g.test(value)
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  mounted () {

  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    buildSingleGateway (gateway) {
      const singleGateway = {
        name: gateway.name || '',
        dataChannel: gateway.dataChennel,
        wakeupChannel: gateway.wakeupChennel,
        beaconChannel: gateway.beaconChennel,
        machId: gateway.machId,
        integMachId: gateway.integMachId,
        refreshStart: gateway.refreshStart,
        siteCode: gateway.siteCode,
        displayNoEnable: gateway.displayNoEnable === 'Yes',
        displayNo: gateway.displayNo
      }
      return singleGateway
    },
    toDashLessMacAddr (mac) {
      if (!commons.isValidStr(mac)) return
      const macAddress = mac.replaceAll('-', '')
      return `${macAddress.substring(0, 6)}FFFE${macAddress.substring(6, 13)}`
    },
    updateSingleGateway () {
      const siteCodeInput = this.$refs.siteCode
      const name = this.$refs.name
      console.log(name)
      console.log(siteCodeInput)

      if (!name.validate()) {
        name.focus()
        return EventBus.$emit(
          'messageAlert',
          this.$t('Enter valid name')
        )
      }
      if (!siteCodeInput.validate()) {
        siteCodeInput.focus()
        return EventBus.$emit(
          'messageAlert',
          this.$t('Enter valid sitecode')
        )
      }

      this.show = false
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.$store.state.dataStore.selectedStore.code,
          gateway: this.toDashLessMacAddr(this.gateway.macAddress),
          siteCode: this.gateway.siteCode
        }
      }
      const singleGateway = this.buildSingleGateway(this.gateway)
      this.$utils
        .callAxiosWithBody(
          requests.updateGateway.method,
          requests.updateGateway.url,
          singleGateway,
          config
        )
        .then(res => {
          EventBus.$emit(
            'messageAlert',
            this.$t('The gateway information has been updated successfully')
          )
          this.$emit('fireUpdateGatewayList')
        })
        .catch(error => {
          EventBus.$emit(
            'messageAlert',
            this.$t('Failed to updated the Gateway')
          )
          console.log(error)
        })
    }
  }
}
</script>
