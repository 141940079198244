var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "700", persistent: "", scrollable: "" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("v-card-title", [
                _c("h3", { staticClass: "page-title-bar" }, [
                  _c("i", { staticClass: "ico ico-gateway" }),
                  _vm._v(_vm._s(_vm.$t("Gateway Detail")) + " ")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("MAC Address") + " *",
                              placeholder: "" + _vm.$t("Input the MAC Address"),
                              disabled: "",
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.gateway["macAddress"],
                              callback: function($$v) {
                                _vm.$set(_vm.gateway, "macAddress", $$v)
                              },
                              expression: "gateway['macAddress']"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            ref: "name",
                            staticClass: "form-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "" + _vm.$t("Name"),
                              placeholder: "" + _vm.$t("Input the name"),
                              rules: [_vm.rules.gatewayName],
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.gateway["name"],
                              callback: function($$v) {
                                _vm.$set(_vm.gateway, "name", $$v)
                              },
                              expression: "gateway['name']"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            ref: "siteCode",
                            staticClass: "form-input",
                            attrs: {
                              rules: [_vm.rules.siteCode],
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Sitecode"),
                              placeholder: _vm.$t("Input the Sitecode"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.gateway.siteCode,
                              callback: function($$v) {
                                _vm.$set(_vm.gateway, "siteCode", $$v)
                              },
                              expression: "gateway.siteCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "" + _vm.$t("Data CH"),
                              placeholder: _vm.$t("Input the Data CH"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.gateway["dataChennel"],
                              callback: function($$v) {
                                _vm.$set(_vm.gateway, "dataChennel", $$v)
                              },
                              expression: "gateway['dataChennel']"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "" + _vm.$t("Wakeup CH"),
                              placeholder: _vm.$t("Input the Wakeup CH"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.gateway["wakeupChennel"],
                              callback: function($$v) {
                                _vm.$set(_vm.gateway, "wakeupChennel", $$v)
                              },
                              expression: "gateway['wakeupChennel']"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Beacon CH ") + "**",
                              placeholder: _vm.$t("Input the Beacon CH"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.gateway["beaconChennel"],
                              callback: function($$v) {
                                _vm.$set(_vm.gateway, "beaconChennel", $$v)
                              },
                              expression: "gateway['beaconChennel']"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Machine ID"),
                              placeholder: _vm.$t("Input the Int Machine ID"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.gateway["machId"],
                              callback: function($$v) {
                                _vm.$set(_vm.gateway, "machId", $$v)
                              },
                              expression: "gateway['machId']"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Int Machine ID") + " **",
                              placeholder:
                                "" + _vm.$t("Input the Int Machine ID"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.gateway["integMachId"],
                              callback: function($$v) {
                                _vm.$set(_vm.gateway, "integMachId", $$v)
                              },
                              expression: "gateway['integMachId']"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "" + _vm.$t("Refresh Start"),
                              placeholder:
                                "" + _vm.$t("Input the Refresh Start"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.gateway["refreshStart"],
                              callback: function($$v) {
                                _vm.$set(_vm.gateway, "refreshStart", $$v)
                              },
                              expression: "gateway['refreshStart']"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            staticClass: "form-select",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "" + _vm.$t("Number Display"),
                              placeholder:
                                "" + _vm.$t("Choose the Number Display"),
                              items: ["Yes", "No"],
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.gateway["displayNoEnable"],
                              callback: function($$v) {
                                _vm.$set(_vm.gateway, "displayNoEnable", $$v)
                              },
                              expression: "gateway['displayNoEnable']"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "" + _vm.$t("Number"),
                              placeholder: "" + _vm.$t("Input the Number"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.gateway["displayNo"],
                              callback: function($$v) {
                                _vm.$set(_vm.gateway, "displayNo", $$v)
                              },
                              expression: "gateway['displayNo']"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-col")
                    ],
                    1
                  ),
                  _c("p", { staticClass: "label-txt mt-5" }, [
                    _c("span", [
                      _c("strong", [_vm._v("*")]),
                      _vm._v(" " + _vm._s(_vm.$t("Mandatory")))
                    ])
                  ]),
                  _c("p", { staticClass: "label-txt mt-1" }, [
                    _c("span", [
                      _c("strong", [_vm._v("**")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "Gateways will be rebooted by changing the config"
                            )
                          ) +
                          " "
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.updateSingleGateway }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.show = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }